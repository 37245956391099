body {
    background-color: #f3f3fa;
    font-family: Arial, sans-serif;
}

/* Container for Phone Input */
.PhoneContainer {
    max-width: 700px;
    margin: 10vh auto; /* Responsive vertical margin */
    padding: 2rem; /* Use rem for responsive padding */
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 50px rgba(242, 102, 2, 0.1);
    text-align: center;
}

/* App title styles */
.app-title {
    font-size: 1.5rem; /* Use rem for font size */
    font-weight: bold;
    color: #333;
}

/* Logo styling */
.logo {
    width: 100%;
    max-width: 400px;
    margin: 2rem 0; /* Use rem for margins */
    border-radius: 200px;
    box-shadow: 0 0 20px 20px rgb(241, 122, 3);
}

/* Header and description styles */
.header {
    font-size: 1.375rem; /* Use rem for font size */
    color: #444;
    margin: 1.25rem 0; /* Use rem for margins */
}

.description {
    font-size: 1rem; /* Use rem for font size */
    color: #666;
    margin-bottom: 1.25rem; /* Use rem for margins */
}

/* Input container with flexbox */
.input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.25rem; /* Use rem for margins */
    gap: 1rem; /* Add gap between boxes */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Enable horizontal scrolling if necessary */
}

/* Country code prefix */
.prefix {
    font-size: 1.4375rem; /* Use rem for font size */
    margin-right: 0.625rem; /* Use rem for margins */
    color: #333;
}

/* Individual digit input */
.digit-input {
    width: 3rem; /* Use rem for width */
    text-align: center;
    margin-right: 0.625rem; /* Use rem for margins */
    padding: 0.625rem; /* Use rem for padding */
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1.125rem; /* Use rem for font size */
}

.digit-input:focus {
    border-color: #f57309;
    outline: none;
}

/* Button styles */
.btn {
    background-color: #d46221;
    color: white;
    border: none;
    padding: 0.625rem 1.25rem; /* Use rem for padding */
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem; /* Use rem for font size */
    transition: background-color 0.3s;
}

.btn:hover {
    background-color: #dd7915 !important;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
    .PhoneContainer {
        width: 90%;
        margin: 5vh auto; /* Responsive vertical margin */
        padding: 2rem; /* Use rem for responsive padding */
    }

    .app-title {
        font-size: 1.25rem; /* Use rem for font size */
    }

    .header {
        font-size: 1.125rem; /* Use rem for font size */
    }

    .description {
        font-size: 0.875rem; /* Use rem for font size */
    }

    .input-container {
        gap: 0.5rem; /* Reduce gap between boxes */
        flex-wrap: nowrap; /* Prevent wrapping of inputs */
        overflow-x: auto; /* Enable horizontal scroll if needed */
    }

    .prefix {
        font-size: 1.125rem; /* Use rem for font size */
        margin-right: 0.625rem; /* Use rem for margins */
    }

    .digit-input {
        width: 2.5rem; /* Use rem for width */
        padding: 0.5rem; /* Use rem for padding */
        font-size: 1rem; /* Use rem for font size */
    }

    .btn {
        font-size: 0.875rem; /* Use rem for font size */
        padding: 0.5rem 1rem; /* Use rem for padding */
    }
}

@media (max-width: 480px) {
    .PhoneContainer {
        width: 100%;
        margin: 2vh auto; /* Responsive vertical margin */
        padding: 1.5rem; /* Use rem for padding */
    }

    .app-title {
        font-size: 1.125rem; /* Use rem for font size */
    }

    .header {
        font-size: 1rem; /* Use rem for font size */
    }

    .description {
        font-size: 0.8125rem; /* Use rem for font size */
    }

    .input-container {
        gap: 0.4rem; /* Reduce gap further for smaller screens */
        flex-wrap: nowrap; /* Ensure inputs stay side by side */
        overflow-x: auto; /* Ensure horizontal scrolling */
    }

    .digit-input {
        width: 2rem; /* Use rem for width */
        padding: 0.375rem; /* Use rem for padding */
        font-size: 0.875rem; /* Use rem for font size */
    }

    .btn {
        font-size: 0.75rem; /* Use rem for font size */
        padding: 0.375rem 0.75rem; /* Use rem for padding */
    }
}
