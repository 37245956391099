body {
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    margin: 0;
    padding: 0;
  }
  
  .container {
    max-width: 500px;
    margin: 100px auto;
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h1 {
    color: #333;
  }
  
  p {
    color: #666;
  }
  
  input[type="tel"], input[type="text"] {
    padding: 10px;
    width: 100%;
    margin: 10px 0;
    box-sizing: border-box;
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .next {
    background-color: #3498db;
    color: white;
  }
  