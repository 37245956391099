/* OtpInput.css */

.container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 50px rgba(242, 102, 2, 0.1);
    text-align: center;
  }
  
  .otp-input-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    gap: 1rem; /* Add gap between input fields */
  }
  
  .otp-input {
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 18px;
  }
  
  .otp-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .btn {
    background-color: #d46221;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .btn:hover {
    background-color: #dd7915;
  }
  